import { useMemo } from "react";
import axios from "axios";
import { navigate } from "gatsby";

import { getUserData as getUserDataFromClient } from "../functions/utils";
import { Flash } from "../functions/Flash";
import { getVatIdInfos, analyzeBillingInfo } from "../functions/utils";

import { useUpdateUserData as lang } from "../../lang/utils/user-hooks-and-provider/lang"


// List of error that don't need to display a message to the user when updating plan
const errorWithoutMsgForUser = [
    "error-attaching-payment-method",
    "error-updating-default-payment",
    "error-retrieving-payment-methods",
    "error-retrieving-stripe-customer",
    "error-creating-subscription"
]


export const useUpdateUserData = ({getUserData, refreshToken, firebase, userData, dispatchUserData}) => {



    // ================================= GET ================================= //

        //------------- Request to get more invoices-------------//

            const getInvoices = useMemo(() => {

                return async (invoiceTargeted) => {
                    const userToken = await refreshToken();

                    return axios.post("/api/get/invoices", {
                        userToken,
                        uid: userData?.user?.uid,
                        email: userData?.user?.email,
                        ...invoiceTargeted
                    })
                    .then(res => {
                        if(res.data.invoices?.length) {
                            return res.data.invoices;
                        }else{
                            Flash({state: "warning", msg: lang.flash_no_more_invoices});
                            return null;
                        }
                    })
                    .catch(error => {
                        Flash({state: "error", msg: lang.flash_invoices_retrieving_error});
                        return false;
                    })
                };
            }, [refreshToken, userData?.user?.uid, userData?.user?.email]);




        //------------- Request to get year usage -------------//

            const getYearUsage = useMemo(() => {

                return async (year, periodIds) => {
                    const userToken = await refreshToken();

                    return axios.post("/api/get/year-usage", {
                        userToken,
                        uid: userData?.user?.uid,
                        email: userData?.user?.email,
                        periodIds
                    })
                    .then(res => {
                        dispatchUserData({
                            type: "update",
                            values: {
                                subAndPerByYear: {
                                    ...userData?.subAndPerByYear,
                                    [year]: {
                                        ...userData?.subAndPerByYear?.[year],
                                        periodIds: res.data.yearUsage
                                    }
                                }
                            },
                            isInit: true
                        });
                        return true;
                    })
                    .catch(error => {
                        Flash({state: "error", msg: lang.flash_subscription_usage_retrieving_error});
                        return false;
                    })
                };
            }, [refreshToken, userData?.user?.uid, userData?.user?.email, userData?.subAndPerByYear]);




        //------------- Get api usage -------------//

        const getApiUsage = useMemo(() => {

            return async () => {
                const userToken = await refreshToken();

                await axios.post("/api/get/api-usage", {
                    userToken,
                    uid: userData?.user?.uid,
                    email: userData?.user?.email
                })
                .then(res => {
                    dispatchUserData({
                        type: "update",
                        values: {
                            ...res.data
                        },
                        isInit: true
                    });
                })
                .catch(error => {
                    Flash({msg: lang.flash_current_usage_retrieving_error, state: "error"});
                    if(typeof window.gtag === "function"){
                        window.gtag('event', 'get', {
                            'event_category' : 'apiUsage',
                            'event_result': "Error",
                            'error_msg': error
                        });
                    };
                });
            }
        }, [refreshToken, userData?.user?.uid, userData?.user?.email]);




    // ================================= SEND ================================= //

        //------------- Request for send verification email -------------//

            const sendVerificationEmail = useMemo(() => {

                return async captchaValue => {
                    const userToken = await refreshToken();

                    if(!captchaValue){
                        Flash({ msg: lang.flash_validate_captcha, state: "error" });
                        return;
                    };

                    return axios.post("/api/user/send-verification-email", {
                        "g_recaptcha_response": captchaValue,
                        userToken,
                        uid: userData?.user?.uid,
                        email: userData?.user?.email,
                    })
                    .then(res => {
                        Flash({ msg: lang.flash_send_verification_email_sucess(userData?.user?.email), state: "success"});
                        return res?.data;
                    })
                    .catch(() => {
                        Flash({ msg: lang.flash_send_verification_email_error, state: "error" });
                        return false;
                    })
                };

            }, [refreshToken, userData?.user?.email, userData?.user?.uid]);
        



    // ================================= UPDATE ================================= //


        //------------- Update choosen plan -------------//

            const updateSelectedPlan = useMemo(() => {
                return planName => {
                    dispatchUserData({
                        type: "update",
                        values: {
                            selectedPlan: planName !== undefined ? userData?.availablePlans[planName] : undefined
                        }
                    });
                }
            }, [userData?.availablePlans]);



        //------------- Request for update privacy -------------//

           const updatePrivacy = useMemo(() => {

            return async (type, value) => {
                const userToken = await refreshToken();

                return axios.post("/api/set/privacy", {
                    userToken,
                    uid: userData?.user?.uid,
                    email: userData?.user?.email,
                    newValue: {
                        type,
                        value
                    }
                })
                .then(res => {
                    if(typeof window.gtag === "function"){
                        window.gtag('event', 'update', {
                            'event_category' : `Update ${type}`,
                            'event_result' : 'Success',
                        });
                    };
                    Flash({state: "success", msg: lang.flash_privacy_success[type]});
                    dispatchUserData({
                        type: "update",
                        values: res.data
                    });
                })
                .catch(err => {
                    if(typeof window.gtag === "function"){
                        window.gtag('event', 'update', {
                            'event_category' : `Update ${type}`,
                            'event_result' : 'Error',
                            'error_msg': err.response?.data?.msg
                        });
                    };
                    Flash({state: "error", msg: lang.flash_privacy_error[type]})
                })
            }

           }, [refreshToken, userData?.user?.uid, userData?.user?.email]);



        //------------- Request for update limit -------------//

            const updateLimit = useMemo(() => {

                return async ({type, value}) => {

                    const oldLimit = userData?.userApiKey?.data?.[type === "hard limit" ? "hardLimit" : "softLimit"];
                    // if(oldLimit === value) return;

                    const userToken = await refreshToken();

                    return axios.post("/api/set/limit", {
                        userToken,
                        uid: userData?.user?.uid,
                        email: userData?.user?.email,
                        type, 
                        value
                    })
                    .then(res => {
                        if(typeof window.gtag === "function"){
                            window.gtag('event', 'update', {
                                'event_category' : `Update ${type}`, // type === "soft limit" || "hard limit"
                                'event_result' : 'Success',
                            });
                        };
                        Flash({state: "success", msg: lang.flash_limit_success[type]});
                        
                        dispatchUserData({
                            type: "update",
                            values: res.data
                        });

                        return true;
                    })
                    .catch(err => {
                        if(typeof window.gtag === "function"){
                            window.gtag('event', 'update', {
                                'event_category' : `Update ${type}`,
                                'event_result' : 'Error',
                                'error_msg': err.response?.data?.msg
                            });
                        };
                        if(lang[err?.response?.data?.msg]){
                            Flash({state: "warning", msg: lang[err?.response?.data?.msg]})
                        }else{
                            Flash({state: "error", msg: lang.flash_limit_error[type]})
                        }
                        return false;
                    })
                };

            }, [refreshToken, userData?.user?.uid, userData?.user?.email, userData?.userApiKey?.data?.hardLimit, userData?.userApiKey?.data?.softLimit]);



        //------------- Request for update name -------------//

            const updateName = useMemo(() => {
                return displayName => {

                    if(displayName === userData.user.displayName){
                        Flash({ msg: lang.flash_update_empty, state: "classic" });
                        return ;
                    };
                    
                    return firebase.updateDisplayName(displayName)
                        .then(() => {
                            if(typeof window.gtag === "function"){
                                window.gtag('event', 'update', {
                                    'event_category' : 'Update name',
                                    'event_result' : 'Success',
                                });
                            };

                            Flash({ msg: lang.flash_update_name_success, state: "success" })

                            const updatedUserInfo = {...userData?.userInfos}
                            updatedUserInfo.displayName = displayName;

                            dispatchUserData({
                                type: "update",
                                values: {
                                    userInfos: updatedUserInfo
                                }
                            });

                            return true;
                        })
                        .catch(() => {
                            if(typeof window.gtag === "function"){
                                window.gtag('event', 'update', {
                                    'event_category' : 'Update name',
                                    'event_result' : 'Error',
                                });
                            };

                            Flash({ msg: lang.flash_update_name_error, state: "error" })

                            return false;
                        });
                };
            }, [userData.user, userData?.userInfos, firebase?.updateDisplayName]);




        //------------- Request for reset password -------------//

            const resetPassword = useMemo(() => {

                return async () => {
                    
                    return firebase.resetPassword(userData?.user.email)
                    .then(() => {
                        Flash({ msg: lang.flash_reset_password_success, state: "success" });
                        if(typeof window.gtag === "function"){
                            window.gtag('event', 'reset', {
                            'event_category' : 'Reset password',
                            'event_result' : 'Success',
                            });
                        };
                        return true;
                    })
                    .catch(err => {
                        Flash({ msg: lang.flash_reset_password_error, state: "error" });
                        if(typeof window.gtag === "function"){
                            window.gtag('event', 'reset', {
                            'event_category' : 'Reset password',
                            'event_result' : 'Error',
                            });
                        };
                        return false;
                    })
                }

            }, [firebase, userData?.user?.email]);




        //------------- Request for update customer infos -------------//

            const updateCustomerInfos = useMemo(() => {

                return async newCustomerInfos => {

                    const {billingIsEmpty, objectsAreDifferent} = analyzeBillingInfo(newCustomerInfos, userData?.customerInfos);

                    if(billingIsEmpty || !objectsAreDifferent){
                        Flash({ msg: lang.flash_update_empty, state: "classic" });
                        return;
                    };

                    const vatIdInfos = getVatIdInfos(newCustomerInfos);

                    if(vatIdInfos && !vatIdInfos?.isValid){
                        Flash({ msg: lang.flash_vat_unvalid, state: "warning" });
                        return;
                    };

                    const userToken = await refreshToken();

                    return axios.post("/api/set/customer-infos", {
                        userToken,
                        uid: userData?.user?.uid,
                        email: userData?.user?.email,
                        customerInfos: newCustomerInfos,
                        vatIdInfos
                    })
                    .then(res => {
                        if(typeof window.gtag === "function"){
                            window.gtag('event', 'update', {
                                'event_category' : 'Update customer infos',
                                'event_result' : 'Success',
                            });
                        };

                        if(!res.data.isCustomerInfosUpdatedReturnError){
                            Flash({state: "success", msg: lang.flash_update_customer_infos_success});
                        }else{
                            Flash({state: "error", msg: lang.flash_update_customer_infos_error})
                        };

                        if(vatIdInfos && !res.data.isVatIdCreationReturnedError){
                            if(vatIdInfos?.value !== userData?.customerInfos?.vatTaxData?.value){
                                Flash({state: "success", msg: lang.flash_update_customer_vat_id_success});
                            }
                        }else if(vatIdInfos){
                            Flash({state: "error", msg: res.data.isVatIdWrongFormat ? lang.flash_vat_unvalid : lang.flash_update_customer_vat_id_error})
                        };
                        
                        dispatchUserData({
                            type: "update",
                            values: res.data.updatedValues
                        });

                        return true;
                    })
                    .catch(err => {
                        if(typeof window.gtag === "function"){
                            window.gtag('event', 'update', {
                                'event_category' : 'Update customer infos',
                                'event_result' : 'Error',
                                'error_msg': err.response?.data?.msg
                            });
                        };
                        if(lang[err?.response?.data?.msg]){
                            Flash({state: "warning", msg: lang[err?.response?.data?.msg]})
                        }else{
                            Flash({state: "error", msg: lang.flash_update_customer_infos_error})
                        }
                        return false;
                    })
                };
            }, [refreshToken, userData?.customerInfos, userData?.user?.uid, userData?.user?.email]);




        //------------- Request for set customer infos (payment) -------------//

            const setCustomerInfos = useMemo(() => {

                return async newCustomerInfos => {

                    const {billingIsEmpty, objectsAreDifferent} = analyzeBillingInfo(newCustomerInfos, userData?.customerInfos);

                    if(billingIsEmpty || !newCustomerInfos?.name || !newCustomerInfos?.email || !newCustomerInfos?.country || !newCustomerInfos?.line1 || !newCustomerInfos?.city){
                        Flash({ msg: lang.flash_missing_customer_infos, state: "classic" });
                        return false;
                    };

                    if(!objectsAreDifferent){
                        return true;
                    };

                    const vatIdInfos = getVatIdInfos(newCustomerInfos);
                    const userToken = await refreshToken();

                    return axios.post("/api/set/customer-infos", {
                        userToken,
                        uid: userData?.user?.uid,
                        email: userData?.user?.email,
                        customerInfos: newCustomerInfos,
                        vatIdInfos
                    })
                    .then(res => {
                        if(typeof window.gtag === "function"){
                            window.gtag('event', 'update', {
                                'event_category' : 'Set customer infos',
                                'event_result' : 'Success',
                            });
                        };
                        if(!res.data.isCustomerInfosUpdatedReturnError){
                            Flash({state: "success", msg: lang.flash_update_customer_infos_success});
                        }else{
                            Flash({state: "warning", msg: lang.flash_update_customer_infos_error})
                        };

                        if(vatIdInfos && !res.data.isVatIdCreationReturnedError){
                            if(vatIdInfos?.value !== userData?.customerInfos?.vatTaxData?.value){
                                Flash({state: "success", msg: lang.flash_update_customer_vat_id_success});
                            }
                        }else if(vatIdInfos){
                            Flash({state: "warning", msg: res.data.isVatIdWrongFormat ? lang.flash_vat_unvalid : lang.flash_update_customer_vat_id_error})
                        };

                        
                        dispatchUserData({
                            type: "update",
                            values: res.data.updatedValues
                        });

                        return true;
                    })
                    .catch(err => {
                        if(typeof window.gtag === "function"){
                            window.gtag('event', 'update', {
                                'event_category' : 'Set customer infos',
                                'event_result' : 'Error',
                                'error_msg': err.response?.data?.msg
                            });
                        };
                        if(lang[err?.response?.data?.msg]){
                            Flash({state: "warning", msg: lang[err?.response?.data?.msg]})
                        }else{
                            Flash({state: "error", msg: lang.flash_update_customer_infos_error})
                        }
                        return false;
                    })
                };
            }, [refreshToken, userData?.customerInfos, userData?.user?.uid, userData?.user?.email]);




        //------------- Request for update email  -------------//

            const updateEmail = useMemo(() => {

                return async (email, captchaValue) => {

                    if(!email){
                        Flash({ msg: lang.flash_email_unvalid, state: "classic" });
                        return;
                    };

                    if(email === userData?.user?.email){
                        Flash({ msg: lang.flash_update_empty, state: "classic" });
                        return;
                    };

                    const userToken = await refreshToken();

                    return axios.post("/api/user/send-modification-email", {
                        "g_recaptcha_response": captchaValue,
                        userToken,
                        uid: userData?.user?.uid,
                        email: userData?.user?.email,
                        newEmail: email
                    })
                    .then(res => {
                        if(typeof window.gtag === "function"){
                            window.gtag('event', 'update', {
                                'event_category' : 'Update email',
                                'event_result' : 'Success',
                            });
                        };
                        Flash({state: "success", msg: lang.flash_update_email_success});
                        return true;
                    })
                    .catch(err => {
                        if(typeof window.gtag === "function"){
                            window.gtag('event', 'update', {
                                'event_category' : 'Update email',
                                'event_result' : 'Error',
                                'error_msg': err.response?.data?.msg
                            });
                        };
                        if(lang.flash_email_errors[err?.response?.data?.msg]){
                            Flash({state: "warning", msg: lang.flash_email_errors[err?.response?.data?.msg]})
                        }else{
                            Flash({state: "error", msg: lang.flash_update_email_error})
                        }
                        return false;
                    })
                };
            }, [userData?.user?.email, refreshToken, userData?.user?.uid]);






    // ================================= SIGNOUT ================================= //

        const signOut = useMemo(() => {
            return flash => {
                firebase.logout().then(() => {
                    dispatchUserData({
                        type: "reset"
                    });
                    window.localStorage.clear();
                    if(typeof window.gtag === "function"){
                        window.gtag('event', 'sign_out', {
                            'event_category' : 'SignOut',
                        });
                    };
                });
            };
        }, [firebase]);






    // ================================= PAYMENT ================================= //

        //------------- Update default card -------------//

            const updateDefaultCard = useMemo(() => {

                return async card => {
                    const userToken = await refreshToken();

                    return axios.post("/api/set/default-card", {
                        userToken,
                        uid: userData?.user?.uid,
                        email: userData?.user?.email,
                        cardToUpdate: card
                    })
                    .then(res => {
    
                        if(typeof window.gtag === "function"){
                            window.gtag('event', "update", {
                                'event_category' : "Update default card",
                                'event_result' : 'Success',
                            });
                        };
    
                        Flash({ msg: lang.flash_update_default_card_sucess, state: "success" });
    
                        dispatchUserData({
                            type: "update",
                            values: res.data
                        });
    
                        return true;
                    })
                    .catch(err => {
    
                        if(typeof window.gtag === "function"){
                            window.gtag('event', "update", {
                                'event_category' : "Update default card",
                                'event_result': "Error",
                                'error_msg': err.response?.data?.msg
                            });
                        };
    
                        if(lang.flash_update_cards_errors[err?.response?.data?.msg]){
                            Flash({state: "warning", msg: lang.flash_update_cards_errors[err?.response?.data?.msg]})
                        }else{
                            Flash({ msg: lang.flash_update_default_card_error, state: "error" });
                        }
    
                        return false;
                      });
                };
            }, [refreshToken, userData?.user?.uid, userData?.user?.email]);


            

        //------------- Request for delete card -------------//

            const deleteCard = useMemo(() => {
                return async card => {
                    const userToken = await refreshToken();

                    return axios.post("/api/set/delete-card", {
                        userToken,
                        uid: userData?.user?.uid,
                        email: userData?.user?.email,
                        cardToDelete: card
                    })
                    .then(res => {

                        if(typeof window.gtag === "function"){
                            window.gtag('event', "delete", {
                                'event_category' : "Delete card",
                                'event_result' : 'Success',
                            });
                        };

                        Flash({ msg: lang.flash_delete_card_success, state: "success" });

                        if(res.data.hasLimitBeenUpdated){
                            Flash({ msg: lang.flash_limit_success["hard limit"], state: "classic" });
                        };

                        dispatchUserData({
                            type: "update",
                            values: res.data.updatedDatas
                        });

                        return true;
                    })
                    .catch(err => {

                        if(typeof window.gtag === "function"){
                            window.gtag('event', "delete", {
                                'event_category' : "Delete card",
                                'event_result': "Error",
                                'error_msg': err.response?.data?.msg
                            });
                        };

                        if(lang.flash_delete_cards_errors[err?.response?.data?.msg]){
                            Flash({state: "warning", msg: lang.flash_delete_cards_errors[err?.response?.data?.msg]})
                        }else{
                            Flash({ msg: lang.flash_delete_card_error, state: "error" });
                        }

                        return false;
                    });
                };
            
            }, [refreshToken, userData?.user?.uid, userData?.user?.email]);



        //------------- Request for resume payment -------------//

            const resumePayment = useMemo(() => {
                return async () => {
                    const userToken = await refreshToken();

                    return axios.post("/api/user/resume-last-payment", {
                        userToken,
                        uid: userData?.user?.uid,
                        email: userData?.user?.email
                    })
                    .then(res => {

                        if(typeof window.gtag === "function"){
                            window.gtag('event', "payment", {
                                'event_category' : "Resume payment",
                                'event_result' : 'Success',
                            });
                        };

                        if(res.data.retryLastPaymentSucceed){
                            Flash({ msg: lang.flash_resume_payment_success, state: "success" });
                        }else{
                            Flash({ msg: lang.flash_resume_payment_failed, state: "error" });
                        };

                        dispatchUserData({
                            type: "update",
                            values: res.data.updatedDatas
                        });

                        return true;
                    })
                    .catch(err => {

                        if(typeof window.gtag === "function"){
                            window.gtag('event', "payment", {
                                'event_category' : "Resume payment",
                                'event_result': "Error",
                                'error_msg': err.response?.data?.msg
                            });
                        };

                        if(lang.flash_resume_payment_errors?.[err?.response?.data?.msg]){
                            Flash({state: "warning", msg: lang.flash_resume_payment_errors?.[err?.response?.data?.msg]})
                        }else{
                            Flash({ msg: lang.flash_resume_payment_error, state: "error" });
                        }

                        return false;
                    });
                };
            
            }, [refreshToken, userData?.user?.uid, userData?.user?.email]);





        //------------- Request for generate setup intent to confirm card setup -------------//

            const generateSetupIntent = useMemo(() => {

                return async () => {
                    const userToken = await refreshToken();

                    return axios.post("/api/set/setup-intent", {
                        userToken,
                        uid: userData?.user?.uid,
                        email: userData?.user?.email
                    })
                    .then(res => {

                        if(typeof window.gtag === "function"){
                            window.gtag('event', "set", {
                                'event_category' : "Set setup intent",
                                'event_result' : 'Success',
                            });
                        };

                        return res.data.setupIntent;
                    })
                    .catch(err => {

                        if(typeof window.gtag === "function"){
                            window.gtag('event', "set", {
                                'event_category' : "Set setup intent",
                                'event_result': "Error",
                                'error_msg': err.response?.data?.msg
                            });
                        };

                        Flash({ msg: lang.flash_setup_intent_error, state: "error" });

                        return false;
                    });
                };
            }, [refreshToken, userData?.user?.uid, userData?.user?.email]);





        //------------- Request for generate payment intent -------------//

            const generatePaymentIntent = useMemo(() => {

                return async () => {
                    const userToken = await refreshToken();

                    return axios.post("/api/set/payment-intent", {
                        userToken,
                        uid: userData?.user?.uid,
                        email: userData?.user?.email,
                        planId: userData?.selectedPlan?.id
                    })
                    .then(res => {

                        if(typeof window.gtag === "function"){
                            window.gtag('event', "set", {
                                'event_category' : "Set payment intent",
                                'event_result' : 'Success',
                            });
                        };

                        return res.data.paymentIntent;
                    })
                    .catch(err => {

                        if(err.response?.data?.msg === "error-same-plan"){
                            Flash({state: "warning", msg: lang.flash_same_plan_error});
                            setTimeout(() => {
                                navigate(lang.prices_link);
                            }, 3000);

                            return false;
                        }

                        if(typeof window.gtag === "function"){
                            window.gtag('event', "set", {
                                'event_category' : "Set payment intent",
                                'event_result': "Error",
                                'error_msg': err.response?.data?.msg
                            });
                        };

                        Flash({ msg: lang.flash_payment_intent_error, state: "error" });

                        return false;
                    });
                };
            }, [refreshToken, userData?.user?.uid, userData?.user?.email, userData?.selectedPlan?.id]);





        //------------- Update payment intent -------------//

            const updatePaymentIntent = useMemo(() => {

                return async (paymentIntentId, cardId) => {
                    const userToken = await refreshToken();

                    return axios.post("/api/set/update-payment-intent", {
                        userToken,
                        uid: userData?.user?.uid,
                        email: userData?.user?.email,
                        paymentIntentId,
                        cardId
                    })
                    .then(res => {
                        return res.data.updatedPaymentIntent;
                    })
                    .catch(err => {

                        Flash({ msg: lang.flash_update_payment_intent_error, state: "error" });

                        return false;
                    });
                };
            }, [refreshToken, userData?.user?.uid, userData?.user?.email]);






        //------------- Request for adding payment method -------------//

            const addPaymentMethod = useMemo(() => {

                return async (setupIntentId, makeItDefaultCard) => {
                    const userToken = await refreshToken();

                    return axios.post("/api/set/payment-method", {
                        userToken,
                        uid: userData?.user?.uid,
                        email: userData?.user?.email,
                        setupIntentId,
                        makeItDefaultCard
                    })
                    .then(res => {

                        if(typeof window.gtag === "function"){
                            window.gtag('event', "add", {
                                'event_category' : "Add card",
                                'event_result' : 'Success',
                            });
                        };

                        Flash({ msg: lang.flash_add_payment_sucess, state: "success" });

                        dispatchUserData({
                            type: "update",
                            values: res.data
                        });

                        return true;
                    })
                    .catch(err => {

                        if(typeof window.gtag === "function"){
                            window.gtag('event', "add", {
                                'event_category' : "Add card",
                                'event_result': "Error",
                                'error_msg': err.response?.data?.msg
                            });
                        };

                        Flash({ msg: lang.flash_add_payment_error, state: "error" });

                        return false;
                    });
                };
            }, [refreshToken, userData?.user?.uid, userData?.user?.email]);




        //------------- Request for update plan  -------------//

            const updatePlan = useMemo(() => {

                return async ({setupIntentId, cardId, paymentIntentId}) => {
                    const userToken = await refreshToken();

                        return axios.post("/api/set/plan", {
                            userToken,
                            uid: userData?.user?.uid,
                            email: userData?.user?.email,
                            planId: userData?.selectedPlan?.id,
                            setupIntentId,
                            paymentIntentId,
                            cardId,
                            date: new Date()
                        })
                        .then(res => {

                            if(res.data?.msg === "requires_action"){
                                return {
                                    requiresAction: true,
                                    clientSecret: res.data.clientSecret
                                }
                            };

                            if(typeof window.gtag === "function"){
                                const isDowngrade = userData?.selectedPlan?.price < userData?.userPlan?.price;
                                
                                window.gtag('event', 'upgrade', {
                                    'event_category' : 'Submited plan',
                                    'event_label' : 'Payment section',
                                    'event_result': 'Success',
                                    'selected_plan': userData?.selectedPlan?.name,
                                    'downgrade_plan': isDowngrade ? "true" : "false",
                                    'downgrade_value': isDowngrade ? userData?.userPlan?.price - userData?.selectedPlan?.price : 0,
                                    'upgrade_value': !isDowngrade ? userData?.selectedPlan?.price - userData?.userPlan?.price : 0,
                                    'old_plan': userData?.userPlan?.name,
                                    'subscribed_plan_value': userData?.selectedPlan?.price,
                                    'value': userData?.selectedPlan?.price
                                });
                            };
    
                            Flash({ msg: lang.return_flash_update_plan_success(userData?.selectedPlan?.name), state: "success" });
                            window.localStorage.removeItem("selectedPlan");
                            getUserData();
                            return "success";
                        })
                        .catch(err => {

                            if(typeof window.gtag === "function"){
                                window.gtag('event', 'upgrade', {
                                  'event_category' : 'Submited plan',
                                  'event_label' : 'Payment section',
                                  'event_result': 'Error',
                                  'selected_plan': userData?.selectedPlan?.name,
                                  'value': userData?.selectedPlan?.price,
                                  'error_msg': err.response?.data?.msg
                                });
                            };

                            if(lang.flash_update_plan_errors?.[err?.response?.data?.msg]){
                                Flash({state: "error", msg: lang.flash_update_plan_errors?.[err?.response?.data?.msg]})
                            }else{
                                Flash({ msg: lang.return_flash_update_plan_error(userData?.selectedPlan?.name), state: "error" });
                            };
    
                            return (err?.response?.status === 400 || errorWithoutMsgForUser.includes(err?.response?.data?.msg)) ? "unvalid" : "error";
                        });
                    }
            }, [refreshToken, userData?.user?.uid, userData?.user?.email, userData?.selectedPlan?.name, 
                userData?.selectedPlan?.price, userData?.selectedPlan?.id, userData?.userPlan?.price, userData?.userPlan?.name]);




        //------------- Remove selected plan  -------------//

            const removeSelectedPlan = useMemo(() => {
                return () => {
                    dispatchUserData({
                        type: "update",
                        values: {
                            selectedPlan: undefined
                        }
                    });
                }
            }
            , []);



    // ================================= DELETE ================================= //



        //------------- Request for user deletion  -------------//

            const deleteAccount = useMemo(() => {

                return async (captchaValue) => {
                    const userToken = await refreshToken();

                    return axios.post("/api/user/delete-account", {
                        "g_recaptcha_response": captchaValue,
                        userToken,
                        uid: userData?.user?.uid,
                        email: userData?.user?.email,
                        userData: getUserDataFromClient()
                    })
                    .then(res => {

                        if(typeof window.gtag === "function"){
                            window.gtag('event', "delete", {
                                'event_category' : "Delete account",
                                'event_result' : 'Success',
                                'event_type': res.data?.type
                            });
                        };

                        Flash({ msg: lang.flash_delete_account_sucess[res.data?.type], state: "success" });
                        signOut();

                        return true;
                    })
                    .catch(err => {

                        if(typeof window.gtag === "function"){
                            window.gtag('event', "delete", {
                                'event_category' : "Delete account",
                                'event_result': "Error",
                                'error_msg': err.response?.data?.msg
                            });
                        };
                        
                        if(lang.flash_delete_account_errors?.[err?.response?.data?.msg]){
                            Flash({state: "warning", msg: lang.flash_delete_account_errors?.[err?.response?.data?.msg]})
                        }else{
                            Flash({ msg: lang.flash_delete_account_error, state: "error" });
                        };

                        return false;
                    });
                };
            }, [refreshToken, signOut, userData?.user?.uid, userData?.user?.email]);




 




    return({
        getInvoices,
        getYearUsage,
        getApiUsage,
        sendVerificationEmail,
        updateSelectedPlan,
        updatePrivacy,
        updateLimit,
        updateName,
        resetPassword,
        updateCustomerInfos,
        setCustomerInfos,
        updateEmail,
        updateDefaultCard,
        deleteCard,
        resumePayment,
        generateSetupIntent,
        generatePaymentIntent,
        updatePaymentIntent,
        addPaymentMethod,
        updatePlan,
        removeSelectedPlan,
        deleteAccount,
        signOut
    })
};