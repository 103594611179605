/* ================================================================================================
                                        GESTION DES STRINGS
==================================================================================================*/

export const regexfilename = /[ \w-]+?(?=\.)|[ \w-]+$/;
export const regexFileExtension = /\.[0-9a-z]+$/;    
export const regexPathElements = /[a-zA-Z0-9_-]+\//g;
export const regexCamelCase = /[A-Z][a-z]+|[a-z]+|[0-9]+|[A-Z]+/g;


//----------------- Générer une id -----------------//

    export const generateId = () => `i${Math.random().toString(36).substr(2, 9)}`;



//----------------- Générer une clé aléatoire -----------------//

    export const generateRandomString = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };


//----------------- Fonction pour convertir une chaine avec - en CamelCase -----------------//

    export const stringToCamelCase = string => {
        let stringCC = "";
        let flag;

        for (var i = 0; i < string.length; i++) {
            const car = string.charAt(i);
            if(car !== "-"){
                stringCC += flag ? car.toUpperCase() : car;
                flag = false;
            }else flag = true;
        }

        return stringCC
    };





//----------------- Fonction mettre en majuscule la première lettre -----------------//

    export const firstLetterUppercase = string => {
        if (typeof string !== 'string') return ''
        return string.charAt(0).toUpperCase() + string.slice(1)
    };



//----------------- Fonction pour formater un string en nom de composant -----------------//

    export const formatInComponentName = string => firstLetterUppercase(string)
        .split(' ')
        .map(el => firstLetterUppercase(el))
        .join("")
        .split('_')
        .map(el => firstLetterUppercase(el))
        .join("")
        .split("-")
        .map(el => firstLetterUppercase(el))
        .join("");


//----------------- Fonction pour formater un string en nom de variable -----------------//

    export const formatInVariableName = string => string
    .split(' ')
    .map((el, index) => index ? firstLetterUppercase(el): el)
    .join("")
    .split('_')
    .map((el, index) => index ? firstLetterUppercase(el): el)
    .join("")
    .split("-")
    .map((el, index) => index ? firstLetterUppercase(el): el)
    .join("");


//----------------- Formater en format css -----------------//

    export const formatInCSSClassName = name => {
        let idName = name;
        if(name.includes(' ')) idName = name.split(' ').join('-');
        const splitUpperCase = idName.match(regexCamelCase);
        idName = splitUpperCase?.join('-')?.toLowerCase() || name.toLowerCase();
        return idName;
    };



//----------------- Formater en nom de fichier  -----------------//

    export const formatInFilename = name => {
        let idName = name;
        if(name.includes(' ')) idName = name.split(' ').join('-');
        const splitUpperCase = idName.match(regexCamelCase);
        idName = splitUpperCase?.join('-')?.toLowerCase() || name.toLowerCase();
        return idName;
    };


//----------------- Formater en format nom de dossier -----------------//

    export const formatInFolderName = name => {
        let folderName = name;
        if(name.includes(' ')) folderName = name.split(' ').join('_');
        const splitUpperCase = folderName.match(regexCamelCase);
        folderName = splitUpperCase?.join('_')?.toLowerCase() || name.toLowerCase();
        return folderName;
    };
    
    



//----------------- Générer un espace -----------------//

    export const generateSpace = string => {
        const result = string.replace( /([A-Z])/g, " $1" );
        const finalResult = (result.charAt(0).toUpperCase() + result.slice(1)).trim();
        return(finalResult)
    };




//----------------- Spliter le path -----------------//

    export const splitPath = (str, separator) => {
        separator = (separator === '/' || separator === undefined) ? str.includes('/') ? '/' : '\\' : separator;
        return str.split(separator);
    };