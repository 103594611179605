import React, {useReducer, useMemo, useEffect} from "react";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { useInitUser } from "./useInitUser";
import { useUpdateUserData } from "./useUpdateUserData";
import { FirebaseContext, useAuth } from "../firebase";
import { availablePlans } from "../list/availablePlans";



export const UserData = React.createContext({});
export const UpdateUserData = React.createContext({});

const initialUserData = {
    availablePlans,
};



function userReducer(state, action) {
    switch (action.type) {
        case 'init':
            return {...state, user: action.user, updateKey: Math.random()};
        case 'update':
            if(!action.isInit){
                window.localStorage.setItem("updateKey", state.updateKey);
            };
            return {...state, ...action.values};
        case 'reset':
            return initialUserData;
      default:
            throw new Error();
    };
};




export const Provider = (props) => {

    const [userData, dispatchUserData] = useReducer(userReducer, initialUserData);
    const {user, firebase, loading} = useAuth();
    const initUser = useInitUser({firebase, userData, dispatchUserData});
    const updateUserData = useUpdateUserData({...initUser, userData, dispatchUserData, firebase});


    //----------------- Update data if user change -----------------//
    useMemo(() => {
        if(user && !loading){
            if(user?.uid !== userData?.user?.uid){
                dispatchUserData({type: "init", user});
            }else if(user?.accessToken !== userData?.user?.accessToken){
                dispatchUserData({
                    type: "update", 
                    values: user,
                    isInit: true
                });
            }
        };
        if(!user && !loading && userData?.user){
            dispatchUserData({type: "reset"});
            window.localStorage.clear();
        };
    }, [user, loading, userData?.user?.accessToken]);



    //------------------ Update data between tabs ------------------//
    useEffect(() => {
        let active = true;

        const updateData = () => {

            if(user && !loading && active && userData?.updateKey){
                const updateKey = window.localStorage.getItem('updateKey');
                const needsToUpdateUserData = updateKey && updateKey !== 'undefined' && JSON.parse(updateKey)?.updateKey !== userData.updateKey;
        
                if(needsToUpdateUserData){
                    dispatchUserData({type: "init", user});
                    window.localStorage.removeItem('updateKey');
                };
            };
        };
        window.addEventListener('storage', updateData);

        return () => { 
            active = false;
            window.removeEventListener('storage', updateData);
        };

    }, [user, loading, userData.updateKey]);

    
    return(
        <UserData.Provider value={userData}>
        <UpdateUserData.Provider value={updateUserData}>
        <FirebaseContext.Provider value={{ user, firebase, loading }}>
            {props.children}
        </FirebaseContext.Provider>
        </UpdateUserData.Provider>
        </UserData.Provider>
    )
}